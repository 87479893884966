* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
   
}
nav {
  justify-content: space-between;
  padding: 0 10%;
  height: 4.5rem;
  width: 100vw;
  display: flex;
  z-index: 99;
  position: sticky;
  top: 0;
}

/*Styling logo*/
.logo {
  display: flex;
  align-items: center;
}

/*Styling Links*/
.nav-links {
  display: flex;
  gap: 50px;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  /* text-transform: uppercase; */
}
.nav-links li a {
  text-decoration: none;
  margin: 0 0.7vw;
}

.nav-links li {
  position: relative;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  margin: 5px;
  transition: all 0.3s ease;
}
.hamburger {
  display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 800px) {
  .logo {
    margin-left: 5%;
  }
  nav {
    position: fixed;
    z-index: 3;
    padding: 0;
  }
  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.5s ease;
  }
  .nav-links {
    font-size: 1.2rem;
    position: fixed;
    /* background: #131418; */
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 0.5s ease-out;
    pointer-events: none;
    justify-content: center;
  }
  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
  }
  .nav-links li {
    opacity: 0;
  }
  .nav-links li:nth-child(1) {
    transition: all 0.3s ease 0.1s;
  }
  .nav-links li:nth-child(2) {
    transition: all 0.3s ease 0.2s;
  }
  .nav-links li:nth-child(3) {
    transition: all 0.3s ease 0.3s;
  }
  .nav-links li:nth-child(4) {
    transition: all 0.3s ease 0.4s;
  }
  .nav-links li:nth-child(5) {
    transition: all 0.3s ease 0.5s;
  }
  .nav-links li:nth-child(6) {
    transition: all 0.3s ease 0.6s;
    margin: 0;
  }
  .nav-links li:nth-child(7) {
    transition: all 0.3s ease 0.7s;
    margin: 0;
  }
  li.fade {
    opacity: 1;
  }
}
/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
