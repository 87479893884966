.pie-chart {
  text-align: center;
  padding: 2rem;
  width: 100%;

  h1 {
    font-size: clamp(1rem, 2vw, 6rem);
  }

  .chart-data {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: clamp(40px, 6vw, 200px);
    width: 100%;
    transition: all 0.5s ease-in-out;

    svg {
      transform: translateX(300px);
      opacity: 0;
      transition: all 0.5s ease-in-out;
      .slice {
        cursor: pointer;
        transform: translate(var(--transform-value), var(--transform-value))
          scale(1);
        stroke: #ddd;
        transition: all 0.3s ease-in-out;

        .dark & {
          stroke: #333;
        }

        &.fade {
          opacity: 0.2;
        }

        &.hovered {
          opacity: 1;
          transform: translate(var(--transform-value), var(--transform-value))
            scale(1.1);
        }
      }

      .slice-text {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        transform: translate(var(--transform-value), var(--transform-value));
        pointer-events: none;
        fill: black;
        font-size: clamp(1rem, 2vw, 4rem);

        .dark & {
          fill: white;
        }

        &.hovered {
          pointer-events: all;
          opacity: 1;
        }
      }

      .inner-circle {
        fill: white;

        .dark & {
          fill: #00112c;
        }
      }
    }

    .slice-data-container {
      transform: translateX(-300px);
      opacity: 0;
      transition: all 0.5s ease-in-out;
      .slice-data {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: clamp(5px, 2vw, 20px);
        margin-top: clamp(5px, 2vw, 20px);
        font-size: clamp(0.6rem, 1.2vw, 2.5rem);

        .slice-color {
          border: 1px solid black;
          width: clamp(20px, 2.5vw, 80px);
          height: clamp(20px, 2.5vw, 80px);

          .dark & {
            border: 1px solid white;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      transform: translateY(200px);

      .slice-data-container {
        transform: translate(0);
        .slice-data {
          font-size: clamp(0.6rem, 3vw, 1.5rem);
        }

        .slice-color {
          width: clamp(20px, 10vw, 40px);
          height: clamp(20px, 10vw, 40px);
        }
      }

      svg {
        transform: translate(0);
        .slice-text {
          font-size: clamp(0.6rem, 3vw, 1.5rem);
        }
      }
    }
  }

  &.appear {
    .chart-data {
      transform: translate(0);
      svg {
        transform: translate(0, 0);
        opacity: 1;
      }

      .slice-data-container {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
}
