.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
  padding-top: 3rem;
  position: relative;

  .dark & {
    background-color: #00112c;
    color: white;
  }

  .welcome-section {
    width: 100%;
    padding-top: 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: black;
    gap: 2rem;

    .welcome-section-info {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
      transform: translateX(200px);
      opacity: 0;
      transition: all 0.5s ease-in-out;

      h1 {
        font-size: clamp(1rem, 2vw, 4rem);
      }
      h2 {
        font-size: clamp(0.75rem, 1.6vw, 3.2rem);
      }

      .profile-stats {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        table {
          border-spacing: clamp(0.5rem, 1.5vw, 2.5rem);
          border-collapse: separate;

          td {
            font-size: clamp(0.8rem, 1.3vw, 3rem);
            padding: 0.5rem;
          }
          td:nth-child(2) {
            color: #00a3ff;
          }
        }
      }
    }

    .image-container {
      padding: clamp(1rem, 2vw, 6rem);
      background-color: #00a3ff;

      img {
        width: clamp(200px, 20vw, 600px);
        height: clamp(200px, 20vw, 600px);
      }
    }

    &.appear {
      .welcome-section-info {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .dark & {
      color: white;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 3rem;

      .welcome-section-info {
        align-items: center;
        width: 100%;

        .profile-stats table {
          border-spacing: clamp(0.1rem, 1vw, 0.5rem);
          td {
            font-size: clamp(1rem, 3vw, 3rem);
            padding: clamp(0.1rem, 1vw, 1rem);
          }
        }
      }
    }
  }

  .log-out-btn {
    position: absolute;
    top: 30px;
    right: 50px;
    padding: clamp(0.25rem, 0.5vw, 1rem) clamp(1rem, 2vw, 3rem);
    border: none;
    font-size: clamp(0.5rem, 1.2vw, 3rem);
    border-radius: clamp(0.25rem, 0.5vw, 1rem);
    background-color: #00a3ff;
    color: white;
    border: 2px solid #00a3ff;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: white;
      color: #00a3ff;
    }
  }

  footer {
    width: 100%;
    padding: 1rem 0;
    font-size: 1.8rem;
    background-color: #00a3ff;
    color: white;
    text-align: center;
  }
}
