#difficulty-chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.5s ease-in-out;

  h1 {
    font-size: clamp(1.5rem, 2vw, 5rem);
  }
  .difficulty-charts-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 clamp(3rem, 8vw, 8rem);

    svg {
      .color-arc {
        filter: drop-shadow(0 0 5px #00b894);
      }
    }
    .difficulty-bars-parent {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: clamp(2rem, 3vw, 8rem);
      padding: 2rem;

      .difficulty-chart {
        width: 100%;

        .progress-bar {
          width: 100%;
          height: clamp(25px, 3vw, 100px);
          border-radius: clamp(30px, 3vw, 100px);
          position: relative;
          overflow: hidden;

          .difficulty-bar {
            width: 100%;
            height: 100%;
            transition: width 1s ease-in-out;

            &.lower-bar {
              opacity: 0.1;
            }

            &.upper-bar {
              position: absolute;
              inset: 0;
              width: 0;
            }
          }
        }

        &.easy-chart {
          .difficulty-bar {
            background-color: #00b8a3;
          }

          .upper-bar {
            filter: drop-shadow(0 0 10px #00b894);
          }
        }

        &.medium-chart {
          .difficulty-bar {
            background-color: orange;
          }

          .upper-bar {
            filter: drop-shadow(0 0 10px orange);
          }
        }

        &.hard-chart {
          .difficulty-bar {
            background-color: red;
          }

          .upper-bar {
            filter: drop-shadow(0 0 10px red);
          }
        }

        .difficulty-bar-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: clamp(0.25rem, 0.5vw, 1.5rem) 0;
          padding: 0 1rem;

          p {
            font-size: clamp(0.6rem, 1.2vw, 2.5rem);
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;

      .difficulty-bars-parent {
        width: 100%;

        .difficulty-chart {
          .progress-bar {
            height: clamp(20px, 6vw, 40px);
          }

          .difficulty-bar-text {
            p {
              font-size: clamp(0.5rem, 2.5vw, 1.25rem);
            }
          }
        }
      }
    }
  }

  &.appear {
    transform: translateY(0);
    opacity: 1;
  }
}
