.loader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  background-color: white;

  h1 {
    font-size: 2.5rem;
    font-family: sans-serif;
    color: black;

    .dark & {
      color: white;
    }
  }

  .loader-dot-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .loader-dot {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #00a3ff;
      animation: loader 1s infinite;
    }

    .loader-dot:nth-child(2) {
      animation-delay: 0.1s;
    }

    .loader-dot:nth-child(3) {
      animation-delay: 0.2s;
    }

    @keyframes loader {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-15px);
      }

      100% {
        transform: translateY(0);
      }
    }
  }

  .dark & {
    background-color: #00112c;
  }
}
