.pie-chart {
  text-align: center;
  padding: 2rem;
  width: 100%;
}
.pie-chart h1 {
  font-size: clamp(1rem, 2vw, 6rem);
}
.pie-chart .chart-data {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: clamp(40px, 6vw, 200px);
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.pie-chart .chart-data svg {
  transform: translateX(300px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.pie-chart .chart-data svg .slice {
  cursor: pointer;
  transform: translate(var(--transform-value), var(--transform-value)) scale(1);
  stroke: #ddd;
  transition: all 0.3s ease-in-out;
}
.dark .pie-chart .chart-data svg .slice {
  stroke: #333;
}
.pie-chart .chart-data svg .slice.fade {
  opacity: 0.2;
}
.pie-chart .chart-data svg .slice.hovered {
  opacity: 1;
  transform: translate(var(--transform-value), var(--transform-value)) scale(1.1);
}
.pie-chart .chart-data svg .slice-text {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translate(var(--transform-value), var(--transform-value));
  pointer-events: none;
  fill: black;
  font-size: clamp(1rem, 2vw, 4rem);
}
.dark .pie-chart .chart-data svg .slice-text {
  fill: white;
}
.pie-chart .chart-data svg .slice-text.hovered {
  pointer-events: all;
  opacity: 1;
}
.pie-chart .chart-data svg .inner-circle {
  fill: white;
}
.dark .pie-chart .chart-data svg .inner-circle {
  fill: #00112c;
}
.pie-chart .chart-data .slice-data-container {
  transform: translateX(-300px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.pie-chart .chart-data .slice-data-container .slice-data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: clamp(5px, 2vw, 20px);
  margin-top: clamp(5px, 2vw, 20px);
  font-size: clamp(0.6rem, 1.2vw, 2.5rem);
}
.pie-chart .chart-data .slice-data-container .slice-data .slice-color {
  border: 1px solid black;
  width: clamp(20px, 2.5vw, 80px);
  height: clamp(20px, 2.5vw, 80px);
}
.dark .pie-chart .chart-data .slice-data-container .slice-data .slice-color {
  border: 1px solid white;
}
@media screen and (max-width: 768px) {
  .pie-chart .chart-data {
    flex-direction: column;
    transform: translateY(200px);
  }
  .pie-chart .chart-data .slice-data-container {
    transform: translate(0);
  }
  .pie-chart .chart-data .slice-data-container .slice-data {
    font-size: clamp(0.6rem, 3vw, 1.5rem);
  }
  .pie-chart .chart-data .slice-data-container .slice-color {
    width: clamp(20px, 10vw, 40px);
    height: clamp(20px, 10vw, 40px);
  }
  .pie-chart .chart-data svg {
    transform: translate(0);
  }
  .pie-chart .chart-data svg .slice-text {
    font-size: clamp(0.6rem, 3vw, 1.5rem);
  }
}
.pie-chart.appear .chart-data {
  transform: translate(0);
}
.pie-chart.appear .chart-data svg {
  transform: translate(0, 0);
  opacity: 1;
}
.pie-chart.appear .chart-data .slice-data-container {
  transform: translate(0, 0);
  opacity: 1;
}/*# sourceMappingURL=pie-chart.css.map */