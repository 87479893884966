html {
  scroll-behavior: smooth;
}

::selection {
  background: #00080d;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.8rem;
}


.dark *::-webkit-scrollbar-track {
  background: #00112c;
}

*::-webkit-scrollbar-thumb {
  background-color: #033b5c;
  border-radius: 10px;
  /* border: 3px solid #b0ecff; */
}
h1, h2, h3, h4, h5, h6 {
  font-size: inherit; /* Customize as needed */
}

a {
  color: blue; /* Customize the link color */
  text-decoration: underline;
}

ul, ol {
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}

blockquote {
  padding: 10px;
  border-left: 5px solid #ddd;
  background-color: #f9f9f9;
  margin: 20px 0;
}