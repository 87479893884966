#heat-map-container {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
#heat-map-container h1 {
  text-align: center;
  font-size: clamp(1.5rem, 2vw, 5rem);
}
#heat-map-container #heat-map-svg {
  transition: inherit;
}
#heat-map-container #heat-map-svg rect {
  stroke: #ddd;
  stroke-width: 1px;
}
#heat-map-container #heat-map-svg rect.hovered {
  stroke: #000;
  stroke-width: 2px;
}
.dark #heat-map-container #heat-map-svg rect.hovered {
  stroke: white;
}
.dark #heat-map-container #heat-map-svg rect {
  stroke: #000;
}
#heat-map-container #heat-map-svg .heat-map-month-text {
  fill: black;
  text-anchor: middle;
  transition: inherit;
}
.dark #heat-map-container #heat-map-svg .heat-map-month-text {
  fill: white;
}
#heat-map-container #heat-map-legend {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  padding: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out, pointer-events 0.2s ease-in-out;
}
#heat-map-container #heat-map-legend.appear {
  opacity: 1;
  pointer-events: all;
}
.dark #heat-map-container #heat-map-legend {
  background-color: #00112c;
  color: white;
  border: 1px solid white;
}
#heat-map-container .heat-map-options {
  display: flex;
  justify-content: space-between;
  padding: clamp(0.6rem, 1vw, 2rem) clamp(1rem, 2vw, 4rem);
  align-items: center;
}
#heat-map-container .heat-map-options h1 {
  font-size: clamp(1rem, 2vw, 3rem);
}
#heat-map-container .heat-map-options select {
  padding: clamp(0.3rem, 0.5vw, 1.5rem);
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}
.dark #heat-map-container .heat-map-options select {
  background-color: #00112c;
  color: white;
  border: 1px solid white;
}
#heat-map-container.appear {
  transform: translateY(0);
  opacity: 1;
}/*# sourceMappingURL=heat-map.css.map */