
.swiper {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.swiper-slide {
  /* background-position: center;
  background-size: cover; */
  width: 300px;
  height: 300px;
}   
